import StepsValidationsNotificationsItem from "@components/TemplateValidationsNotifications/StepsValidationsNotificationItem";
import { formatMultipleListItems } from "@utils/formatting";
import { Trans } from "react-i18next";

import i18n from "../../i18n";

const I18N_BASE_PATH = "src.utils.templateValidations.templateValidationsMessages";
let labelsTranslated = {};
let stepsDescriptionTranslated = {};

const templateValidationNotificationsItemStyles = "text-sm !mb-0 text-black";

i18n.on("languageChanged", () => {
  labelsTranslated.defaultFlowName = i18n.t("general.label.defaultFlowName", "padrão");
  labelsTranslated.flow = i18n.t(`${I18N_BASE_PATH}.methods.flowNamesMessages.flow`, "no fluxo");
  labelsTranslated.flows = i18n.t(`${I18N_BASE_PATH}.methods.flowNamesMessages.flows`, "nos fluxos");

  stepsDescriptionTranslated.WELCOME = i18n.t("general.steps.WELCOME.description", "Boas vindas");
  stepsDescriptionTranslated.CAMERA_ACCESS = i18n.t("general.steps.CAMERA_ACCESS.description", "Permissões de câmera");
  stepsDescriptionTranslated.DATA_CONFIRMATION = i18n.t(
    "general.steps.DATA_CONFIRMATION.description",
    "Dados pessoais",
  );
  stepsDescriptionTranslated.DD = i18n.t("general.steps.DD.description", "Captura de Documento");
  stepsDescriptionTranslated.DISPATCH_ADDRESS = i18n.t(
    "general.steps.DISPATCH_ADDRESS.description",
    "Endereço residencial",
  );
  stepsDescriptionTranslated.DOCUMENT_TYPE = i18n.t("general.steps.DOCUMENT_TYPE.description", "Tipo de documento");
  stepsDescriptionTranslated.USING_TERMS = i18n.t("general.steps.USING_TERMS.description", "Aceite de termos de uso");
  stepsDescriptionTranslated.SEND_DOCUMENT_TYPE = i18n.t(
    "general.steps.SEND_DOCUMENT_TYPE.description",
    "Forma de envio de documento",
  );
  stepsDescriptionTranslated.DONE = i18n.t("general.steps.DONE.description", "Tudo certo");
  stepsDescriptionTranslated.INCOME = i18n.t("general.steps.INCOME.description", "Faturamento");
  stepsDescriptionTranslated.PFL = i18n.t("general.steps.PFL.description", "Selfie");
  stepsDescriptionTranslated.FA = i18n.t("general.steps.FA.description", "Autenticação Facial");
  stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION = i18n.t(
    "general.steps.PHONE_NUMBER_VALIDATION.description",
    "Validar telefone",
  );
  stepsDescriptionTranslated.EMAIL_VALIDATION = i18n.t("general.steps.EMAIL_VALIDATION.description", "Validar email");
  stepsDescriptionTranslated.UPLOAD_DOCUMENTS = i18n.t(
    "general.steps.UPLOAD_DOCUMENTS.description",
    "Upload de arquivos",
  );
  stepsDescriptionTranslated.PF_PF_DATA = i18n.t("general.steps.PF_PF_DATA.description", "Dados da pessoa relacionada");
  stepsDescriptionTranslated.BUSINESS_ADDRESS = i18n.t(
    "general.steps.BUSINESS_ADDRESS.description",
    "Endereço empresarial",
  );
  stepsDescriptionTranslated.COMPANY_DATA = i18n.t("general.steps.COMPANY_DATA.description", "Dados empresariais");
  stepsDescriptionTranslated.EMPLOYEE_DATA = i18n.t("general.steps.EMPLOYEE_DATA.description", "Dados do funcionário");
  stepsDescriptionTranslated.QSA = i18n.t("general.steps.QSA.description", "Quem é você?");
  stepsDescriptionTranslated.ATTORNEY = i18n.t("general.steps.ATTORNEY.description", "Quem você representa?");
  stepsDescriptionTranslated.CUSTOM = i18n.t("general.steps.CUSTOM.description", "Etapa customizada");
  stepsDescriptionTranslated.INSTRUCTIONS = i18n.t("general.steps.INSTRUCTIONS.description", "Instruções");
  stepsDescriptionTranslated.FLOW_CHOICE = i18n.t("general.steps.FLOW_CHOICE.description", "Decisão");
  stepsDescriptionTranslated.SMART_CHOICE = i18n.t("general.steps.SMART_CHOICE.description", "Decisão inteligente");
  stepsDescriptionTranslated.CUSTOM_FORM = i18n.t("general.steps.CUSTOM_FORM.description", "Formulário");
  stepsDescriptionTranslated.DOCUMENT_ISSUED_COUNTRY = i18n.t(
    "general.steps.DOCUMENT_ISSUED_COUNTRY.description",
    "País de emissão do documento",
  );
  stepsDescriptionTranslated.LIVENESS_CLEARSALE = i18n.t(
    "general.steps.LIVENESS_CLEARSALE.description",
    "Selfie ClearSale",
  );
  stepsDescriptionTranslated.LIVENESS_FACETEC_3D = i18n.t(
    "general.steps.LIVENESS_FACETEC_3D.description",
    "Selfie FaceTec 3D",
  );
  stepsDescriptionTranslated.LIVENESS_FACETEC_2D = i18n.t(
    "general.steps.LIVENESS_FACETEC_2D.description",
    "Selfie FaceTec 2D",
  );
  stepsDescriptionTranslated.LIVENESS_IPROOV = i18n.t("general.steps.LIVENESS_IPROOV.description", "Selfie iProov");
});

// custom function to dynamically generate messages (singular or plural) about steps validations
const flowNamesMessages = (flowNames) => {
  // [] array is an 'object' type in javascript
  const message =
    typeof flowNames === "object" ? labelsTranslated?.flows || "nos fluxos" : labelsTranslated?.flow || "no fluxo";
  const flows = typeof flowNames === "object" ? `${formatMultipleListItems(flowNames)}` : flowNames;

  return { message, flows };
};

// Template validations messages (errors and warnings)
export const templateValidationsMessages = {
  VARIABLE_NAMES_DUPLICATED: "VARIABLE_NAMES_DUPLICATED",
  VARIABLE_NAMES_NOT_ALLOWED: "VARIABLE_NAMES_NOT_ALLOWED",
  DOMAIN_IS_NOT_VALID: "DOMAIN_IS_NOT_VALID",
  HELP_BUTTON_URL_IS_NOT_VALID: "HELP_BUTTON_URL_IS_NOT_VALID",
  UPLOAD_DOCUMENTS_OTHER_FIELDS_TITLE_DUPLICATED: "UPLOAD_DOCUMENTS_OTHER_FIELDS_TITLE_DUPLICATED",
  CAMERA_ACCESS_IS_IN_FRONT_OF_DD_OR_PFL: "CAMERA_ACCESS_IS_IN_FRONT_OF_DD_OR_PFL",
  COMPANY_DATA_IS_IN_FRONT_OF_BUSINESS_ADDRESS: "COMPANY_DATA_IS_IN_FRONT_OF_BUSINESS_ADDRESS",
  COMPANY_DATA_STEP_DOES_NOT_EXIST: "COMPANY_DATA_STEP_DOES_NOT_EXIST",
  CUSTOM_FORM_FIELDS_IDENTIFIERS_MISSING: "CUSTOM_FORM_FIELDS_IDENTIFIERS_MISSING",
  CUSTOM_FORM_IDENTIFIER_MISSING: "CUSTOM_FORM_IDENTIFIER_MISSING",
  "DATA_CONFIRMATION_MUST_HAS_EMAIL_FIELD_IN_CASE_OF_EMAIL-VALIDATION":
    "DATA_CONFIRMATION_MUST_HAS_EMAIL_FIELD_IN_CASE_OF_EMAIL-VALIDATION",
  "DATA_CONFIRMATION_MUST_HAS_PHONE_NUMBER_FIELD_IN_CASE_OF_PHONE_NUMBER-VALIDATION":
    "DATA_CONFIRMATION_MUST_HAS_PHONE_NUMBER_FIELD_IN_CASE_OF_PHONE_NUMBER-VALIDATION",
  "THE_DATA_CONFIRMATION_MUST_HAVE_A_CPF_FIELD_IN_CASE_OF_A_PHONE_NUMBER-VALIDATION":
    "THE_DATA_CONFIRMATION_MUST_HAVE_A_CPF_FIELD_IN_CASE_OF_A_PHONE_NUMBER-VALIDATION",
  DD_IS_IN_FRONT_OF_DATA_CONFIRMATION: "DD_IS_IN_FRONT_OF_DATA_CONFIRMATION",
  DOCUMENT_TYPE_IS_IN_FRONT_OF_SEND_DOCUMENT_TYPE: "DOCUMENT_TYPE_IS_IN_FRONT_OF_SEND_DOCUMENT_TYPE",
  DOCUMENT_TYPE_HAS_ANY_OPTION: "DOCUMENT_TYPE_HAS_ANY_OPTION",
  DOCUMENT_TYPE_IS_IN_FRONT_OF_DD: "DOCUMENT_TYPE_IS_IN_FRONT_OF_DD",
  SEND_DOCUMENT_TYPE_IS_IN_FRONT_OF_DD: "SEND_DOCUMENT_TYPE_IS_IN_FRONT_OF_DD",
  "PF_PF_DATA_HAS_NEITHER_EMAIL_NOR_PHONE-NUMBER_FIELD": "PF_PF_DATA_HAS_NEITHER_EMAIL_NOR_PHONE-NUMBER_FIELD",
  FLOW_NAMES_DUPLICATED: "FLOW_NAMES_DUPLICATED",
  WITHOUT_FLOW_OPTIONS: "WITHOUT_FLOW_OPTIONS",
  STEPS_DUPLICATED: "STEPS_DUPLICATED",
  TEMPLATE_HAS_NOT_ONE_DOCUMENT_TYPE: "TEMPLATE_HAS_NOT_ONE_DOCUMENT_TYPE",
  TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED: "TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED",
  TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED_USING_TERMS: "TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED_USING_TERMS",
  "TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_EMAIL-VALIDATION":
    "TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_EMAIL-VALIDATION",
  "TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_PHONE_NUMBER-VALIDATION":
    "TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_PHONE_NUMBER-VALIDATION",
  TEMPLATE_MUST_HAVE_DD_OR_PFL_TO_ENABLE_CAMERA_ACCESS: "TEMPLATE_MUST_HAVE_DD_OR_PFL_TO_ENABLE_CAMERA_ACCESS",
  TEMPLATE_MUST_HAS_DD_TO_ENABLE_SEND_DOCUMENT_TYPE: "TEMPLATE_MUST_HAS_DD_TO_ENABLE_SEND_DOCUMENT_TYPE",
  TEMPLATE_SHOULD_HAS_CAMERA_ACCESS_DD_PFL_ENABLED: "TEMPLATE_SHOULD_HAS_CAMERA_ACCESS_DD_PFL_ENABLED",
  TEMPLATE_MUST_HAVE_DOCUMENT_TYPE_OR_DOCUMENT_ISSUED_COUNTRY:
    "TEMPLATE_MUST_HAVE_DOCUMENT_TYPE_OR_DOCUMENT_ISSUED_COUNTRY",
  SMART_CHOICE_SHOULD_BE_DOCUMENT_TYPE: "SMART_CHOICE_SHOULD_BE_DOCUMENT_TYPE",
  HAS_CAMERA_ACCESS_ON_ALL_FLOWS: "HAS_CAMERA_ACCESS_ON_ALL_FLOWS",
  DOCUMENT_TYPES_SHOULD_BE_IN_FLOWS: "DOCUMENT_TYPES_SHOULD_BE_IN_FLOWS",
  QSA_STEP_HAS_BOTH_FLAGS_ON: "QSA_STEP_HAS_BOTH_FLAGS_ON",
  ATTORNEY_FLOWS_HAS_POWER_OF_ATTORNEY: "ATTORNEY_FLOWS_HAS_POWER_OF_ATTORNEY",
  CPF_VALIDATION_STEP_LIVENESS: "CPF_VALIDATION_STEP_LIVENESS",
  CPF_VALIDATION_STEP_FA: "CPF_VALIDATION_STEP_FA",
};

// Types for template validations messages
const templateValidationsMessagesType = {
  ERROR: "error",
  WARNING: "warning",
};

// Add a new template notification (error or warning) based on template validation name
export const validateTemplateMessages = (validationName = "") => {
  let validation = {};

  switch (validationName) {
    // Variables validations
    case templateValidationsMessages.VARIABLE_NAMES_DUPLICATED:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.VARIABLE_NAMES_DUPLICATED`}>
              Existem <strong>nomes de variáveis</strong> que são idênticos. Não é permitido um mesmo nome para duas ou
              mais variáveis diferentes
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.QSA_STEP_HAS_BOTH_FLAGS_ON:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.QSA_STEP_HAS_BOTH_FLAGS_ON`}></Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.ATTORNEY_FLOWS_HAS_POWER_OF_ATTORNEY:
      validation = {
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.ATTORNEY_FLOWS_HAS_POWER_OF_ATTORNEY`}
            ></Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.CPF_VALIDATION_STEP_LIVENESS:
      validation = {
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.CPF_VALIDATION_STEP`}></Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.CPF_VALIDATION_STEP_FA:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.CPF_VALIDATION_STEP`}></Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.VARIABLE_NAMES_NOT_ALLOWED:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.VARIABLE_NAMES_NOT_ALLOWED`}>
              A variável <strong>token</strong> não é válida. Verifique as instruções de uso das variáveis.
            </Trans>
          </p>
        ),
      };
      break;
    // Other validations
    case templateValidationsMessages.DOMAIN_IS_NOT_VALID:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DOMAIN_IS_NOT_VALID`}>
              O domínio precisa ser uma URL válida para ser possível gerar o link de onboarding da maneira correta
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.HELP_BUTTON_URL_IS_NOT_VALID:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.HELP_BUTTON_URL_IS_NOT_VALID`}>
              O link de redirecionamento do botão de ajuda precisa ser uma URL válida
            </Trans>
          </p>
        ),
      };
      break;
    // Steps validations
    case templateValidationsMessages.UPLOAD_DOCUMENTS_OTHER_FIELDS_TITLE_DUPLICATED:
      validation = () => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.UPLOAD_DOCUMENTS_OTHER_FIELDS_TITLE_DUPLICATED`}
              components={{
                UploadDocumentsStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.UPLOAD_DOCUMENTS} />
                ),
              }}
            >
              Existem <strong>títulos</strong> que são idênticos na etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.UPLOAD_DOCUMENTS} />. Não é
              permitido um mesmo título para dois ou mais arquivos do tipo <strong>Outros</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.CUSTOM_FORM_IDENTIFIER_MISSING:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.CUSTOM_FORM_IDENTIFIER_MISSING`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                CustomFromStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CUSTOM_FORM} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CUSTOM_FORM} /> não contém
              o <strong>id do formulário</strong> {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong>. É crucial informar um identificador para ser
              possível capturar e identificar os dados do formulário após a finalização do Onboarding
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.CUSTOM_FORM_FIELDS_IDENTIFIERS_MISSING:
      validation = (fields) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.CUSTOM_FORM_FIELDS_IDENTIFIERS_MISSING`}
              values={{ fields }}
              components={{
                CustomFromStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CUSTOM_FORM} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CUSTOM_FORM} /> não contém
              o identificador <strong>(id)</strong> para os campos <strong>{fields}</strong> do formulário. É crucial
              informar um identificador para esses campos sendo possível capturar e identificar esses dados no
              formulário após a finalização do Onboarding
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.FLOW_NAMES_DUPLICATED:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.FLOW_NAMES_DUPLICATED`}>
              Existem <strong>nomes de fluxos</strong> que são idênticos. Não é permitido um mesmo nome para dois ou
              mais nomes de fluxos diferentes, pois irá ocasionar em conflitos no fluxo de onboarding
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.WITHOUT_FLOW_OPTIONS:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.WITHOUT_FLOW_OPTIONS`}>
              A <strong>etapa de decisão</strong> não possui <strong>fluxos de decisão</strong> vinculados.
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.STEPS_DUPLICATED:
      validation = (stepName, flow) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.STEPS_DUPLICATED`}
              values={{ flowName: flow || labelsTranslated?.defaultFlowName || "padrão" }}
              components={{
                StepName: <StepsValidationsNotificationsItem stepName={stepName} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepName} /> está duplicada no fluxo{" "}
              <strong>{flow || labelsTranslated?.defaultFlowName || "padrão"}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_DOCUMENT_TYPE:
      validation = {
        type: templateValidationsMessagesType.WARNING, // TODO: Change validation to "ERROR" after validation refactor
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_HAS_NOT_ONE_DOCUMENT_TYPE`}
              components={{
                DocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
                ),
              }}
            >
              Pelo menos 1 tipo de documento é obrigatório estar disponível na etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.DOCUMENT_TYPE_HAS_ANY_OPTION:
      validation = {
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DOCUMENT_TYPE_HAS_ANY_OPTION`}>
              O tipo de documento <strong>"ANY"</strong> está selecionado, nesta opção não haverá qualquer tipo de
              verificação de qualidade na imagem, passando toda a responsabilidade para o cliente
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED:
      validation = {
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED`}
              components={{
                DocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
                ),
              }}
            >
              É necessário que pelo menos um <strong>termos de aceite</strong> seja <strong>obrigatório</strong> na
              etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED_USING_TERMS:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_HAS_NOT_ONE_USE_TERMS_REQUIRED_USING_TERMS`}
              components={{
                UsingTermsStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.USING_TERMS} />,
              }}
            >
              É necessário que pelo menos um <strong>termos de aceite</strong> seja <strong>obrigatório</strong> na
              etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.USING_TERMS} />
            </Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.DOCUMENT_TYPE_IS_IN_FRONT_OF_DD:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DOCUMENT_TYPE_IS_IN_FRONT_OF_DD`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} /> deve vir
              antes da etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.SEND_DOCUMENT_TYPE_IS_IN_FRONT_OF_DD:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.SEND_DOCUMENT_TYPE_IS_IN_FRONT_OF_DD`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                SendDocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.SEND_DOCUMENT_TYPE} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.SEND_DOCUMENT_TYPE} />{" "}
              deve vir antes da etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.CAMERA_ACCESS_IS_IN_FRONT_OF_DD_OR_PFL:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.CAMERA_ACCESS_IS_IN_FRONT_OF_DD_OR_PFL`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                CameraAccessStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CAMERA_ACCESS} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
                PFLStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PFL} />,
                FAStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.FA} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CAMERA_ACCESS} /> deve vir
              antes das etapas <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PFL} /> e{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.FA} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser
              possível realizar a(s) captura(s)
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.DD_IS_IN_FRONT_OF_DATA_CONFIRMATION:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DD_IS_IN_FRONT_OF_DATA_CONFIRMATION`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DataConfirmationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} /> está
              antes da <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong> fazendo com
              que a OCR realizada na captura do documento substitua os dados preenchidos anteriormente na etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.DOCUMENT_TYPE_IS_IN_FRONT_OF_SEND_DOCUMENT_TYPE:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DOCUMENT_TYPE_IS_IN_FRONT_OF_SEND_DOCUMENT_TYPE`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                SendDocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.SEND_DOCUMENT_TYPE} />
                ),
                DocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
                ),
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} /> deve vir
              antes da etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.SEND_DOCUMENT_TYPE} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.TEMPLATE_SHOULD_HAS_CAMERA_ACCESS_DD_PFL_ENABLED:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_SHOULD_HAS_CAMERA_ACCESS_DD_PFL_ENABLED`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                CameraAccessStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CAMERA_ACCESS} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
                PFLStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PFL} />,
                FAStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.FA} />,
              }}
            >
              Adicione a etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CAMERA_ACCESS} />{" "}
              para pedir acesso à câmera do usuário antes das etapas{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PFL} /> e/ou{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.FA} />
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.TEMPLATE_MUST_HAVE_DD_OR_PFL_TO_ENABLE_CAMERA_ACCESS:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.WARNING,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_MUST_HAVE_DD_OR_PFL_TO_ENABLE_CAMERA_ACCESS`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                CameraAccessStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CAMERA_ACCESS} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
                PFLStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PFL} />,
                FAStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.FA} />,
              }}
            >
              É necessário adicionar a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PFL} /> e/ou a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.FA} /> para ser possível usufruir
              da etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.CAMERA_ACCESS} /> da
              melhor forma {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.TEMPLATE_MUST_HAS_DD_TO_ENABLE_SEND_DOCUMENT_TYPE:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_MUST_HAS_DD_TO_ENABLE_SEND_DOCUMENT_TYPE`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                SendDocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.SEND_DOCUMENT_TYPE} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
              }}
            >
              É necessário adicionar a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} /> para ser possível usufruir
              da etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.SEND_DOCUMENT_TYPE} /> da
              melhor forma {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages["TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_EMAIL-VALIDATION"]:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_EMAIL-VALIDATION`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DataConfirmationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
                ),
                EmailValidationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.EMAIL_VALIDATION} />
                ),
              }}
            >
              É obrigatório que a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} /> esteja
              adicionada e seja anterior a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.EMAIL_VALIDATION} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser
              possível obter e posteriormente validar o email do usuário
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages["DATA_CONFIRMATION_MUST_HAS_EMAIL_FIELD_IN_CASE_OF_EMAIL-VALIDATION"]:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DATA_CONFIRMATION_MUST_HAS_EMAIL_FIELD_IN_CASE_OF_EMAIL-VALIDATION`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DataConfirmationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
                ),
                EmailValidationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.EMAIL_VALIDATION} />
                ),
              }}
            >
              É obrigatório que a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} /> contenha o
              campo <strong>Email</strong> {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser possível obter esse dado do usuário e
              validá-lo na etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.EMAIL_VALIDATION} />
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages["TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_PHONE_NUMBER-VALIDATION"]:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_PHONE_NUMBER-VALIDATION`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DataConfirmationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
                ),
                PhoneNumberValidationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION} />
                ),
              }}
            >
              É obrigatório que a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} /> esteja
              adicionada e seja anterior a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser
              possível obter e posteriormente validar o telefone do usuário
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages[
      "DATA_CONFIRMATION_MUST_HAS_PHONE_NUMBER_FIELD_IN_CASE_OF_PHONE_NUMBER-VALIDATION"
    ]:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DATA_CONFIRMATION_MUST_HAS_PHONE_NUMBER_FIELD_IN_CASE_OF_PHONE_NUMBER-VALIDATION`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DataConfirmationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
                ),
                PhoneNumberValidationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION} />
                ),
              }}
            >
              É obrigatório que a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} /> contenha o
              campo <strong>Telefone</strong> {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser possível obter esse dado do usuário e
              validá-lo na etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION} />
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages[
      "THE_DATA_CONFIRMATION_MUST_HAVE_A_CPF_FIELD_IN_CASE_OF_A_PHONE_NUMBER-VALIDATION"
    ]:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.THE_DATA_CONFIRMATION_MUST_HAVE_A_CPF_FIELD_IN_CASE_OF_A_PHONE_NUMBER-VALIDATION`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                DataConfirmationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} />
                ),
                PhoneNumberValidationStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION} />
                ),
              }}
            >
              É obrigatório que a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DATA_CONFIRMATION} /> contenha o
              campo <strong>CPF</strong> {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser possível obter esse dado do usuário e
              validá-lo na etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PHONE_NUMBER_VALIDATION} />
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages["PF_PF_DATA_HAS_NEITHER_EMAIL_NOR_PHONE-NUMBER_FIELD"]:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.PF_PF_DATA_HAS_NEITHER_EMAIL_NOR_PHONE-NUMBER_FIELD`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                PFPFData: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PF_PF_DATA} />,
              }}
            >
              É obrigatório que a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.PF_PF_DATA} /> contenha ou o campo{" "}
              <strong>Email</strong> ou o campo <strong>Telefone</strong> {flowNamesMessages(flowNames)?.message}{" "}
              <strong>{flowNamesMessages(flowNames)?.flows}</strong> para ser possível enviar o link de onboarding para
              a pessoa
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.COMPANY_DATA_IS_IN_FRONT_OF_BUSINESS_ADDRESS:
      validation = (flowNames) => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.COMPANY_DATA_IS_IN_FRONT_OF_BUSINESS_ADDRESS`}
              values={{ message: flowNamesMessages(flowNames)?.message, flows: flowNamesMessages(flowNames)?.flows }}
              components={{
                BusinessAddressStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.BUSINESS_ADDRESS} />
                ),
                CompanyDataStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.COMPANY_DATA} />
                ),
              }}
            >
              A etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.BUSINESS_ADDRESS} /> está
              antes da etapa <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.COMPANY_DATA} />{" "}
              {flowNamesMessages(flowNames)?.message} <strong>{flowNamesMessages(flowNames)?.flows}</strong> fazendo com
              que os dados de residência preenchidos anteriormente sejam sobrescritos pelos dados obtidos através da
              consulta do <strong>CNPJ</strong>
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.COMPANY_DATA_STEP_DOES_NOT_EXIST:
      validation = {
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.COMPANY_DATA_STEP_DOES_NOT_EXIST`}
            ></Trans>
          </p>
        ),
      };
      break;
    case templateValidationsMessages.TEMPLATE_MUST_HAVE_DOCUMENT_TYPE_OR_DOCUMENT_ISSUED_COUNTRY:
      validation = () => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.TEMPLATE_MUST_HAVE_DOCUMENT_TYPE_OR_DOCUMENT_ISSUED_COUNTRY`}
              components={{
                DocumentTypeStep: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} />
                ),
                DocumentIssuedCountry: (
                  <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_ISSUED_COUNTRY} />
                ),
                DDStep: <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} />,
              }}
            >
              É necessário adicionar a etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_TYPE} /> e/ou{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DOCUMENT_ISSUED_COUNTRY} /> para
              ser possível usufruir da etapa{" "}
              <StepsValidationsNotificationsItem stepName={stepsDescriptionTranslated.DD} /> da melhor forma{" "}
            </Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.SMART_CHOICE_SHOULD_BE_DOCUMENT_TYPE:
      validation = () => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.SMART_CHOICE_SHOULD_BE_DOCUMENT_TYPE`}
            ></Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.HAS_CAMERA_ACCESS_ON_ALL_FLOWS:
      validation = () => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.HAS_CAMERA_ACCESS_ON_ALL_FLOWS`}
            ></Trans>
          </p>
        ),
      });
      break;
    case templateValidationsMessages.DOCUMENT_TYPES_SHOULD_BE_IN_FLOWS:
      validation = () => ({
        type: templateValidationsMessagesType.ERROR,
        message: (
          <p className={templateValidationNotificationsItemStyles}>
            <Trans
              i18nKey={`${I18N_BASE_PATH}.methods.validateTemplateMessages.DOCUMENT_TYPES_SHOULD_BE_IN_FLOWS`}
            ></Trans>
          </p>
        ),
      });
      break;
    default:
      validation = {};
  }
  return validation;
};
